
import { Paper, Box, Typography, Divider, Select, MenuItem } from "@mui/material"
import AdminSidebar from "../components/adminSideBar"
import Header1 from "../components/header1"
import { useEffect, useState } from "react"
import CustomAxios from "../utils/CustomAxios"
import IndianNumFormat from "../components/numberFormat"
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Calendar } from "primereact/calendar"
import overviewIcon from "./../assets/adminSidebar/overview.svg"
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import './productDetails.css'
// import dayjs from "dayjs"
import { Bar, Line } from 'react-chartjs-2';
const InventryOverview = () => {
    const dateRange = useState([])
    const [overview, setOverview] = useState()
    const [expired, setExpired] = useState()
    const [topSelling, setTopSelling] = useState()
    const [totalPurchasing, setTotalPurchasing] = useState()
    const [totalRemaining, setTotalRemaining] = useState()
    const [totalSales, setTotalSales] = useState()    // const []
    const [anualReport, setAnnualReport] = useState()
    const [products, setProducts] = useState([])
    const [graphProduct, setGraphProduct] = useState()
    const [overviewProduct,setOverviewProduct]=useState()
    const [years,setYears]=useState([])
    const [filteredData,setFilteredData]=useState({})
    const [topSellingProductCategory,setTopSellingProductCategory]=useState()
    const [base,setBase]=useState(false)
    // console.log(base)
    // console.log(graphProduct)
    const [graphData, setGraphData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May'], // X-axis labels
        datasets: [
            {
                label: 'Monthly Sales (in INR)', // Dataset label
                data: [3000, 4000, 3200, 4500, 5000], // Y-axis data
                backgroundColor: "#53AEFF",
                borderWidth: 1, // Border width
            },
        ],
    })
    useEffect(() => {
        collectDashboard()
    }, [filteredData])
    const getYearsRange = (numYears) => {
        const currentYear = new Date().getFullYear();
        let years=Array.from({ length: numYears }, (_, i) => currentYear - (numYears - 1 - i))
        setYears(years.reverse())
        // return Array.from({ length: numYears }, (_, i) => currentYear - (numYears - 1 - i))
      };
useEffect(()=>{
    const years=getYearsRange(5)
    console.log(years)
},[])
    const collectDashboard = () => {

        CustomAxios.post("inventory-overview/",{filteredData}).then(({ data }) => {
            setOverview(data)
            setExpired(data.expired_Damaged)
            setTopSelling(data.topSelling)
            setTotalPurchasing(data.totalPurchase)
            setTotalRemaining(data.totalRemaining)
            setTotalSales(data.totalSales)
            setAnnualReport(data.graphData)
            setProducts(data.productList)
            console.log(data)
            // console.log(data.totalPurchase?.reduce((a,b)=> a+b.totalpurchase,0))

        }).catch(error => { console.log(error) })


    }
    // console.log(overview)
    useEffect(() => {
        // console.log(anualReport)
        let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        const dataSet = months.map(item => {
            const record = anualReport?.filter(data => data.month == item)
            let dataset = {}
            if (record?.length > 0) {
                console.log(record[0])
                dataset.month = dayjs().month(item - 1).format('MMMM')
                dataset.totalSales = record[0].sales_per_month.filter(item => graphProduct ? graphProduct?.id == item.products : item).reduce((a, b) => a + (base?b.totalPrice:b.quantities), 0)
            }
            else {
                dataset.month = dayjs().month(item - 1).format('MMMM')
                dataset.totalSales = 0
            }
            return dataset

            // const totalSales=item.sales_per_month.reduce((a,b)=>a+b.totalPrice,0)
            // // console.log(totalSales)

            // const totalQuantity=item.sales_per_month.reduce((a,b)=>a+b.quantities,0)
            // return totalSales


        })
        // const labels=anualReport.map(item=>dayjs().month(item.month - 1).format('MMMM'))
        // console.log(dataSet,labels)
        console.log(dataSet)
        const data = {
            labels: dataSet.map(item => item.month), // X-axis labels
            datasets: [
                {
                    label:  `Monthly Sales (in ${base? 'INR':'Quantity'})`, // Dataset label
                    data: dataSet.map(item => item.totalSales), // Y-axis data
                    backgroundColor: "#53AEFF",
                    borderWidth: 1, // Border width
                },
            ],
        }
        setGraphData(data)
    }, [anualReport, graphProduct,base])
    const currentYear = dayjs();

    // const data = {
    //     labels: ['January', 'February', 'March', 'April', 'May'], // X-axis labels
    //     datasets: [
    //       {
    //         label: 'Monthly Sales (in INR)', // Dataset label
    //         data: [3000, 4000, 3200, 4500, 5000], // Y-axis data
    //         backgroundColor: "#53AEFF",
    //         borderWidth: 1, // Border width
    //       },
    //     ],
    //   };

    // Chart Options
    const options = {
        responsive: true, // Make the chart responsive
        plugins: {
            legend: {
                display: true, // Show legend
                position: 'top', // Position the legend
            },
            title: {
                display: true, // Show title
                text: 'Monthly Sales Data', // Title text
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Months', // Label for X-axis
                },
            },
            y: {
                title: {
                    display: true,
                    text: `Sales (in ${base? 'INR':'Quantity'})`, // Label for Y-axis
                },
                beginAtZero: true, // Start Y-axis at 0
            },
        },
    };
    const handlePlaceholder=(val,placeholder)=>{
        console.log(val)
        if(!val){
            console.log("value not presented",placeholder)
            return <Typography sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</Typography>
          }
          else{
            return val
          }
          

    }
    // useEffect(()=>{ chart()},[overview])
    const stockNotification = (quantity) => {
        if (quantity <= 0) {
            return <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "end" }}>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}>
                    {quantity}
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#FF5449" }}>
                    Out Stock
                </Typography>

            </Box>
        }
        else if (quantity <= 25) {
            return <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "end" }}>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}>
                    {quantity}
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#FACC15" }}>
                    Low Stock
                </Typography>

            </Box>

        }
        else {
            return <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "end" }}>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}>
                    {quantity}
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#00C561" }}>
                    Instock
                </Typography>

            </Box>
        }

    }

    return <>

        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
            <Header1 />
            <Box sx={{ display: "flex", paddingTop: "144px", gap: "24px", mx: "24px", position: "relative" }}>
                <AdminSidebar />
                <Box className="background" sx={{ width: { xs: "calc(100% )", sm: "100%" }, p: { xs: "0px", sm: "0px" } }}>
                    <Paper sx={{ padding: "24px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <Typography sx={{ fontSize: "20px", lineHeight: "28px", fontWeight: "700" }}>Inventory Management</Typography>
                            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <Typography>Quantity</Typography>
                                {/* <FormControlLabel onChange={} sx={{}} control={}  />
                                 */}
                                 <Switch checked={base} onChange={()=>setBase(!base)} />
                                <Typography>Money Value</Typography>
                            </Box>
                            
                            </Box>
                           
                            <Box sx={{ display: "flex", gap: "10px", alignItems: "center",justifyContent:"space-between" }}>
                                {/* <Box component={'img'} src={stockico} sx={{}} /> */}
                                <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                                <Box component={'img'} src={overviewIcon} sx={{}} />
                                <Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: "400" }}>
                                     Overview
                                </Typography>
                                    </Box>
                                <Box sx={{display:"flex",gap:"20px"}}> 
                                            <Select displayEmpty placeholder="Selece Product"  fullWidth sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "300px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end"},"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} onChange={(e) => setOverviewProduct(e.target.value)} renderValue={(val) => handlePlaceholder(val?.name, 'Products')}>

                                                <MenuItem value={null}>Select All </MenuItem>
                                                {
                                                    products?.map((item, type_id) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item} sx={{ textTransform: "capitalize" }}>{item.name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <Select displayEmpty placeholder="Selece Product"  fullWidth sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "100px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" },"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} onChange={(e) => setFilteredData({ ...filteredData,overViewYear:e.target.value})} renderValue={(val) => handlePlaceholder(val, 'Year')}>

                                                <MenuItem value={null}>Select year</MenuItem>
                                                {
                                                    years?.map((item, type_id) => {
                                                        return (
                                                            <MenuItem key={type_id} value={item} sx={{ textTransform: "capitalize" }}>{item}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            
                                               
                                        </Box>

                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            {/* <Box sx={{}}> */}
                            <Box sx={{ padding: "5px", display: "flex", justifyContent: "space-around", padding: "16px", borderRadius: "5px" }}>
                                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                                        {IndianNumFormat(totalPurchasing?.filter(item =>overviewProduct?  item.products ==overviewProduct?.id:item ).reduce((a, b) => a + (base? b.totalpurchase:b.totalquantity), 0),base)}
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                                        Purchase
                                    </Typography>
                                </Box>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                                        {IndianNumFormat(totalSales?.filter(item => overviewProduct?  item.products ==overviewProduct?.id:item ).reduce((a, b) => a + (base?b.totalSales:b.totalquantity), 0),base)}
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                                        Sales
                                    </Typography>

                                </Box>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                                        {IndianNumFormat(totalRemaining?.filter(item => overviewProduct?  item.products ==overviewProduct?.id:item ).reduce((a, b) => a +(base? b.totalRemaining:b.totalquantity), 0),base)}
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                                        Sales Return
                                    </Typography>

                                </Box>
                                {/* <Divider orientation="vertical" variant="middle" flexItem />
                                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                    <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                                        25,000
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                                        Profit
                                    </Typography>

                                </Box> */}
                                {/* <Divider orientation="vertical" variant="middle" flexItem />
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <Typography sx={{ fontSize: "24px", fontWeigt: "32px", fontWeight: "500" }}>
                                            
                                        </Typography>
                                        <Typography sx={{ fontSize: "14px", fontWeigt: "20px", fontWeight: "400" }}>
                                            Expired
                                        </Typography>

                                    </Box> */}
                            </Box>

                            {/* </Box> */}
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px" }}>
                                    Sales & Trends
                                </Typography>
                                <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", borderRadius: "5px", border: "1px solid #F0F4FC", gap: "20px" }}>

                                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", color: "#3F4752" }}>
                                            Top 5 Selling products
                                        </Typography>
                                        <Box sx={{display:"flex",gap:"20px",alignItems:"center"}}>
                                        <Select displayEmpty placeholder="Selece Product"   fullWidth sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "200px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end"},"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} onChange={(e) => setTopSellingProductCategory(e.target.value)} renderValue={(val) => handlePlaceholder(val, 'Category')}>

                                            <MenuItem value={null}>Select product </MenuItem>
                                           
                                                <MenuItem value='syrup'  sx={{ textTransform: "capitalize" }}>Syrup</MenuItem>
                                                <MenuItem value='capsule' sx={{ textTransform: "capitalize" }}>Capsule</MenuItem>
                                                <MenuItem value='powder' sx={{ textTransform: "capitalize" }}>Powder</MenuItem>
                                                
                                            </Select>
                                            <Box sx={{ display: "flex", position: "relative", alignItems: "center",maxWidth: "220px",width:"100%" }}>
                                            <Calendar placeholder="Data Range" value={filteredData.topSellingDateRange} style={{ borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "220px",fontWeight:"400",'&:hover &:enabled':{borderColor:"white"},"&:focus":{boxShadow:"none"}}} onChange={(e) =>setFilteredData({ ...filteredData,topSellingDateRange:e.value})} selectionMode="range" readOnlyInput hideOnRangeSelection />
                                            <CalendarTodayOutlinedIcon sx={{position:"absolute",right:"10px",pointerEvents: "none",color:"grey",width:"20px",height:"20px"}}/>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
                                        {topSelling?.filter(item=>topSellingProductCategory? item.products.category==topSellingProductCategory:item).map(item => <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                                <Box component='img' src={item.products.cover_details.original} sx={{ bgcolor: "grey", width: "40px", height: "40px" }}>

                                                </Box>
                                                <Box>
                                                    <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "20px", }}> {item.products.name}</Typography>
                                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "20px", color: "#6F7883" }}>{item.products.category}</Typography>
                                                </Box>

                                            </Box>

                                            <Box sx={{ padding: "5px", display: "flex", justifyContent: "space-around", gap: "24px" }}>
                                                <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "end" }}>
                                                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}>
                                                        {item.quantity}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#6F7883" }}>
                                                        Sold
                                                    </Typography>

                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "end", flexDirection: "column", alignItems: "end" }}>
                                                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}>
                                                        {IndianNumFormat(item.price)}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#6F7883" }}>
                                                        INR
                                                    </Typography>

                                                </Box>

                                                {stockNotification(item.products.inventry_details.available_quantity)}

                                            </Box>

                                        </Box>)

                                        }


                                    </Box>

                                </Box>
                                <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", borderRadius: "5px", border: "1px solid #F0F4FC", gap: "20px" }}>

                                    <Box  sx={{display:"flex",alignItems:'center',justifyContent:"space-between"}}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", color: "#3F4752" }}>
                                            Overall Sales
                                        </Typography>
                                        <Box sx={{display:"flex",gap:"20px"}}> 
                                            <Select displayEmpty fullWidth sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "300px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end"},"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} onChange={(e) => setGraphProduct(e.target.value)} renderValue={(val) => handlePlaceholder(val?.name, 'Products')}>

                                                <MenuItem value={''}>Select All </MenuItem>
                                                {
                                                    products?.map((item, type_id) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item} sx={{ textTransform: "capitalize" }}>{item.name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <Select displayEmpty fullWidth sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "100px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end"},"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} onChange={(e) => setFilteredData({ ...filteredData,graphYear:e.target.value})} renderValue={(val) => handlePlaceholder(val, 'Year')}>

                                                {/* <MenuItem value={null}>Select year</MenuItem> */}
                                                {
                                                    years?.map((item, type_id) => {
                                                        return (
                                                            <MenuItem key={type_id} value={item} sx={{ textTransform: "capitalize" }}>{item}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            
                                               
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box>
                                        {/* <div style ={{ width:"100%",height:"800px"}}><canvas id="acquisitions"></canvas></div>
                                     */}
                                        <Bar data={graphData} options={options} style={{ width: "100%", height: "250px" }} />
                                    </Box>

                                </Box>

                            </Box>
                        </Box>

                    </Paper>
                </Box>

            </Box>




        </Box>

    </>


}
export default InventryOverview