import { Box, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, ListItemButton , Menu, Badge, Stack } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Mayi_logo from "../assets/mayi_logo.png";
import { landingPage_styles } from "../landing_page/landing_page";
import { useDispatch, useSelector } from "react-redux";
import Avatar from '@mui/material/Avatar';
import SideBarList from "./list";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { updateCartSideBar } from "../redux/active_bar";
import { Info } from "@mui/icons-material";
import HelpIcon from '@mui/icons-material/Help';
import { jwtDecode } from "jwt-decode";
import { updateFirstName,updateLastName,updateEmail,updateRole,updateId,updateAllAddress } from "../redux/user_reducer";
const Header1 = () => {
    const location = useLocation();
    const UserRole =useSelector(state=>state.user.role)
    console.log(UserRole)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ maxWidth: 900 });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate()
    const cart = useSelector(state=>state.product.cart)
    const product = useSelector(state => state.product.product)
    const [value, setValue] = useState(0);

    const dispatch = useDispatch()
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: "Home", link: "/" },
        { text: "Shop our products", link: "/our_product" },
        { text: "Login", link: "/login" },
    ];

    const [isScrolled, setIsScrolled] = useState(false);
    let profile = useSelector(state => state.user.profile)
    let res = useSelector(state => state.user.member_response)
    // const location=useLocation()
    // console.log(res)
    const authtoken = localStorage.getItem('authtoken');
    const [imagestatus, setImageStatus] = useState(false)
    // // console.log(authtoken)
    const inventary=useSelector(state=>state.admin.inventary)
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElMobile, setAnchorElMobile] = useState(null);
    const open = Boolean(anchorEl);
    const openMobile = Boolean(anchorElMobile);
    const handleClick = (event, location) => {
        console.log(location)
        if (location === "profile") {
            setAnchorEl(event.currentTarget);
        }
        if (location === "menu") {
            setAnchorElMobile(event.currentTarget);
        }
    };
    const handleClose = (event, location) => {
        if (location === "profile") {
            setAnchorEl(null);
        }
        if (location === "menu") {
            setAnchorElMobile(null);
        }
    };
    useEffect(() => {
        if (profile != "") {
            setImageStatus(true)
        }
    }, [profile])
    // const authtoken = localStorage.getItem("authtoken")
    const add=localStorage.getItem('address')
    // // console.log(authtoken)
    // const dispatch = useDispatch()

    useEffect(() => {
        if (authtoken) {
            
            const decode = jwtDecode(authtoken)
            dispatch(updateFirstName(decode.first_name))
            dispatch(updateLastName(decode.last_name))
            dispatch(updateEmail(decode.email))
            console.log("decoding user role")
            dispatch(updateRole(decode.role))
            dispatch(updateId(decode.id))
            // // console.log(decode)
            // handleCart()
            // fetchuserdetails(decode.id)
        }
        // // console.log(add)
        if(add){
            dispatch(updateAllAddress(JSON.parse(add)))
        }
        if (localStorage.getItem("cart")&&!authtoken) {
            // // console.log(localStorage.getItem("cart"))
            const cart = localStorage.getItem("cart")
            // // console.log(cart)
            // dispatch(updateCart(JSON.parse(localStorage.getItem("cart"))))
        }
        else {
            // // console.log("added")
            localStorage.setItem("cart", [])
        }
        

    }, [authtoken,add])
    // // console.log(location)
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const updateProfile = () => {

    }
    let first_name = useSelector(state => state.user.first_name)
    let last_name = useSelector(state => state.user.last_name)

    // // console.log(profile)
    const capitalize = (str) => {
        return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    // console.log(location)
    const logout=()=>{
        localStorage.clear()
        
        if(UserRole){
            var url = '/login';
window.history.go(-window.history.length);
window.location.href = url;
        }
        else{
            navigate("/")
        }
       
        
    }
    return (
        <Box
            sx={{
                top: 0,
                left: 0,
                width: "100%",
                backgroundColor: "#fff",
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                borderBottom: "1px solid", borderColor: "grey.300",
                transition: 'background-color 0.3s ease',
                color: "white",
                zIndex: "20",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "fixed",
                padding: { xs: "16px", md: "30px 120px" }
            }}
        >
            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box component={'img'} src={Mayi_logo} sx={{ width: { xs: "100px", md: "133px" } }} />
            </Box> */}

            {(isMobile || isLaptop)? (
                <>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <Box component={'img'} onClick={()=> navigate("/")}  src={Mayi_logo} sx={{ width: "100px",cursor:"pointer"}} />
                        <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                        {authtoken && res && res.account?.role=="user" && !res?.account?.kyc_status && 
                        <Stack direction="row" sx={[{color:"#DC362E", alignItems:"center",gap:"4px",justifyContent:"flex-end"},isMobile&&{pl:"20px"}]}>
                            <Info sx={{width:"16px",height:"16px"}}/>
                            <Typography sx={{fontWeight:400,fontSize:"12px",lineHeight:"16px",textAlign:"right"}}>{(isMobile || isLaptop)?"":"Complete your KYC for a smooth experience!"} <Link to="/profile#kyc-details" style={{cursor:"pointer",textDecoration:"underline",color:"#DC362E","&:hover":{color:"#DC362E"}}}>{(isMobile || isLaptop)?"Complete your KYC":"Verify Now!"}</Link></Typography>
                        </Stack>}
                          {!authtoken&&   <Badge badgeContent={cart.length} color="error"><ShoppingCartOutlinedIcon onClick={() =>dispatch(updateCartSideBar(true))} sx={{ height: "20px",width:"20px",color:"grey",cursor:"pointer" }} /></Badge>}
                        <IconButton onClick={toggleDrawer(true)} sx={{ color: "#000" }}>
                            <MenuIcon />
                        </IconButton>
                        </Box>
                        
                    </Box>
                </>
            )

                : (
                    <>
                        <Box sx={{ display: "flex", gap: "24px", alignItems: "flex-end",width:"100%",maxWidth:"500px" }}>
                            <Link to="/" style={{ textDecoration: "none" }}>
                                <Typography variant="text" color="inherit" sx={{ borderBottom: location.pathname === '/' ? "2px solid #53AEFF" : "none", fontFamily: "poppins", color: "#000", fontWeight: location.pathname === '/' ? "700" : "400", padding: "8px 0px" }}>Home</Typography>
                            </Link>
                            {UserRole=="user" || !authtoken?<Link to="/our_product" style={{ textDecoration: "none" }}>
                                <Typography variant="text" color="inherit" sx={{ borderBottom: location.pathname === '/our_product' ||location.pathname === `/product_details/${product.id}` ? "2px solid #53AEFF" : "none", fontFamily: "poppins", color: "#000", fontWeight: location.pathname === '/our_product' ||location.pathname === `/product_details/${product.id}`  ? "700" : "400", padding: "8px 0px" }}>Shop our products</Typography>
                            </Link>:
                            UserRole==="admin"?<Link to="/product" style={{ textDecoration: "none" }}>
                                <Typography variant="text" color="inherit" sx={{ borderBottom: inventary ||location.pathname === `/product_details/${product.id}` ? "2px solid #53AEFF" : "none", fontFamily: "poppins", color: "#000", fontWeight: inventary  ? "700" : "400", padding: "8px 0px" }}>Admin panel</Typography>
                            </Link>:null}
                        </Box>

                        <Box component={'img'} onClick={()=> navigate("/")}  src={Mayi_logo} sx={{ width: { xs: "100px", md: "133px" },cursor:"pointer" }} />
                        {!isMobile && !isLaptop && authtoken ? <>{profile && imagestatus ? <Box sx={{width:"100%",maxWidth:"500px",display:"flex",justifyContent:"flex-end",gap:"24px",alignItems:"center"}}>
                        {(authtoken && res && res.account?.role=="user" && !res?.account?.kyc_status) && <Stack direction="row" sx={[{color:"#DC362E", alignItems:"center",gap:"4px",justifyContent:"flex-end"},isMobile&&{pl:"20px"}]}>
                                    <Info sx={{width:"16px",height:"16px"}}/>
                                    <Typography sx={{fontWeight:400,fontSize:"12px",lineHeight:"16px",textAlign:"right"}}>{(isMobile || isLaptop)?"":"Complete your KYC for a smooth experience!"} <Link to="/profile#kyc-details" style={{cursor:"pointer",textDecoration:"underline",color:"#DC362E","&:hover":{color:"#DC362E"}}}>{(isMobile|| isLaptop)?"Complete your KYC":"Verify Now!"}</Link></Typography>
                                </Stack>}
                            <Badge badgeContent={cart.length} color="error">
                                <ShoppingCartOutlinedIcon onClick={() => authtoken?dispatch(updateCartSideBar(true)):navigate("/login")} sx={{ height: "20px",width:"20px",color:"grey",cursor:"pointer" }} />
                            </Badge>
                              <img src={profile} style={{ width: "32px", height: "32px", objectFit: "cover", background: "primary", border: "none", borderRadius: "16px", cursor: "pointer" }} alt='Profile' onError={(e) => { setImageStatus(false) }} onClick={(e) => handleClick(e, 'profile')} />
                            </Box> : 
                            <Box sx={{width:"100%",maxWidth:"500px",display:"flex",justifyContent:"flex-end",gap:"24px",alignItems:"center"}}>
                                {authtoken && res && res.account?.role=="user" && !res?.account?.kyc_status && 
                                <Stack direction="row" sx={[{color:"#DC362E", alignItems:"center",gap:"4px",justifyContent:"flex-end"},isMobile&&{pl:"20px"}]}>
                                    <Info sx={{width:"16px",height:"16px"}}/>
                                    <Typography sx={{fontWeight:400,fontSize:"12px",lineHeight:"16px",textAlign:"right"}}>{(isMobile || isLaptop)?"":"Complete your KYC for a smooth experience!"} <Link to="/profile#kyc-details" style={{cursor:"pointer",textDecoration:"underline",color:"#DC362E","&:hover":{color:"#DC362E"}}}>{(isMobile|| isLaptop)?"Complete your KYC":"Verify Now!"}</Link></Typography>
                                </Stack>}
                                {UserRole === 'user' &&<Badge badgeContent={cart.length} color="error"><ShoppingCartOutlinedIcon onClick={() => authtoken?dispatch(updateCartSideBar(true)):navigate("/login")} sx={{ height: "20px",width:"20px",color:"grey",cursor:"pointer" }} /></Badge>}
                                <Avatar {...stringAvatar(capitalize(first_name) + " " + capitalize(last_name))} onClick={(e) => handleClick(e, 'profile')} style={{ height:"32px",width:"32px",borderRadius:"16px",bgcolor:"#3B82F6",fontSize:"14px",fontWeight:"700",cursor: "pointer", border: authtoken && res && res.account?.role == "user" && !res?.account?.kyc_status ? "2px solid #DC362E" : "unset" }} />
                                <Link to="https://help.mayiiq.com/" target="_blank" >
                <HelpIcon sx={{color:'primary.light',cursor:"pointer",textTransform:"none"}}   />
                </Link>
                         
                                </Box>} </> : !authtoken && <Box sx={{width:"100%",maxWidth:"500px", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "12px" }}>
                <Button sx={{ textDecoration: "none", padding: "0px 16px", border: "none", outline: "none", textTransform: "none", "&:hover": { borderBottom: "3px solid #27a2fd" } }} onClick={() => navigate("/login")}>
                    <Typography variant="text" color="inherit" sx={{ fontFamily: "poppins", color: "#53AEFF", fontSize: "16px", fontWeight: "500", padding: "8px 0px" }}>Login</Typography>
                </Button>
                <Button sx={{ ...landingPage_styles.introduction.button, ...landingPage_styles.buttonAnimation,maxWidth:"140px" }}>
                    <Typography sx={{ ...landingPage_styles.introduction.buttonText, fontSize: "16px", lineHeight: "24px" }} onClick={() => navigate("/signup")}>Get Started</Typography>
                </Button>
                <Link to="https://help.mayiiq.com/" target="_blank" >
                <HelpIcon sx={{color:'primary.light',cursor:"pointer",textTransform:"none"}}   />
                </Link>
                    
                
            </Box>}

                    </>
                )}
           
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{
                        width: 200,
                        background: "rgba(255, 255, 255, 0.9)",
                        backdropFilter: 'blur(6.4px)',
                        padding: "24px 0px 0px",
                        height: "100%"
                    }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        
                            <ListItem   component={Link} to="/" style={{padding:"12px 20px" }}>
                            <ListItemText 
                                primary="Home" 
                                primaryTypographyProps={{
                                    style: {
                                    fontFamily: "Poppins",
                                    fontWeight: location.pathname === "/" ? "700" : "400",
                                    fontSize: "16px",
                                    color: "#000",
                                    textDecoration: "none"
                                    }
                                }} 
                                style={{ textDecoration: "none" }} 
                                />
                            </ListItem>
                           {UserRole=='user'  || !authtoken? <><ListItem   component={Link} to="/our_product" style={{padding:"12px 20px" }}>
                                <ListItemText primary="Shop our products" primaryTypographyProps={{
                                    fontFamily: "Poppins",
                                    fontWeight: location.pathname === "/our_product" ? "700" : "400",
                                    fontSize: "16px",
                                    color: "#000",
                                    textDecoration:"none"
                                }} sx={{textDecoration:"none"}}/>
                            </ListItem>
                            </>:
                            <ListItem   component={Link} to="/product" style={{padding:"12px 20px" }}>
                                <ListItemText primary="Admin panel" primaryTypographyProps={{
                                    fontFamily: "Poppins",
                                    fontWeight: inventary ? "700" : "400",
                                    fontSize: "16px",
                                    color: "#000",
                                    textDecoration:"none"
                                }} sx={{textDecoration:"none"}}/>
                            </ListItem>}
                            {!authtoken?<><ListItem  component={Link} to="/login" style={{padding:"12px 20px" }}>
                                <ListItemText primary= "Login" primaryTypographyProps={{
                                    fontFamily: "Poppins",
                                    fontWeight: location.pathname === "/login" ? "700" : "400",
                                    fontSize: "16px",
                                    color: "#53AEFF",
                                    
                                }} sx={{textDecoration:"none"}}/>
                            </ListItem>
                        <ListItem sx={{ background: "linear-gradient(79.69deg, #00C2FF -0.64%, #00E3AF 107.84%)",padding:"12px 20px" }}>
                            <Button sx={{ width: "100%",display:"flex",justifyContent:"flex-start",padding:0 }} component={Link} to="/signup">
                                <Typography sx={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "16px", color: "#000" }} >Get Started</Typography>
                            </Button>
                        </ListItem></>: <>
                     <Button sx={{ textDecoration: "none",padding:"12px 20px" , border: "none", outline: "none", textTransform: "none",width:"100%"}} onClick={() =>logout()}>
                    <Typography variant="text" color="inherit" sx={{ width:"100%",fontFamily: "poppins", color: "#53AEFF", fontSize: "16px", fontWeight: "500" ,textAlign:"left"}}>Logout</Typography>
                </Button>
                    </>}
                    <ListItem   component={Link} to="https://help.mayiiq.com/" target="_blank" style={{padding:"12px 20px" }}>
                                <ListItemText primary="Help" primaryTypographyProps={{
                                    fontFamily: "Poppins",
                                    fontWeight: location.pathname === "/our_product" ? "700" : "400",
                                    fontSize: "16px",
                                    color: "#000",
                                    textDecoration:"none"
                                }} sx={{textDecoration:"none"}}/>
                            </ListItem>
                    </List>


                   
                </Box>
            </Drawer>
            <Menu
               anchorEl={(isMobile|| isLaptop)?anchorElMobile:anchorEl}
               id="account-menu"
               open={(isMobile|| isLaptop)?openMobile:open}
               onClose={(e)=>{(isMobile|| isLaptop)?handleClose(e,'menu'):handleClose(e,'profile')}}
               onClick={(e)=>{(isMobile|| isLaptop)?handleClose(e,'menu'):handleClose(e,'profile')}}
               PaperProps={{
                 elevation: 0,
                 sx: {
                   overflow: 'visible',
                   filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                   mt: 1.5,
                   '& .MuiAvatar-root': {
                     width: 32,
                     height: 32,
                     ml: -0.5,
                     mr: 1,
                   },
                   '&::before': {
                     content: '""',
                     display: 'block',
                     position: 'absolute',
                     top: 0,
                     right: 14,
                     width: 10,
                     height: 10,
                     bgcolor: 'background.paper',
                     transform: 'translateY(-50%) rotate(45deg)',
                     zIndex: 0,
                   },
                 },
               }}
               transformOrigin={{ horizontal: 'right', vertical: 'top' }}
               anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {!isMobile && !isLaptop&&<SideBarList/>}
                {(isMobile|| isLaptop)&&
          <List>
           
            {/* <ListItem  disablePadding  >
                <ListItemButton component="a" href= {location.pathname === "/"? "#info":"/?scrollto=info"} sx={{height:"30px"}} >
                    
                    <ListItemText primary={"Info"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem> */}

            <ListItem  disablePadding  >
                <ListItemButton component="a" href= {location.pathname === "/"? "#who-we-are":"/?scrollto=who-we-are"}  sx={{height:"30px"}} >
                   
                    <ListItemText primary={"Who We Are"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem  disablePadding  >
                <ListItemButton component="a" href= {location.pathname === "/"? "#ourculture":"/?scrollto=our_Culture"}  sx={{height:"30px"}} >
                   
                    <ListItemText primary={"Our Culture"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem  disablePadding >
                <ListItemButton component={Link} to="/our_product" sx={{height:"30px"}}  >
               
                    <ListItemText primary={"Our Products"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem  disablePadding >
                <ListItemButton component="a" href= {location.pathname === "/"? "#contact":"/?scrollto=contactus"} sx={{height:"30px"}}  >
               
                    <ListItemText primary={"Contact"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem></List>}

            </Menu>
        </Box>
    );
};

export default Header1




