import * as React from 'react';
import { useEffect,useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Box,Stack,InputBase,MenuItem,Button,Typography,Select ,Divider,TablePagination} from '@mui/material';
import { Delete, DeleteOutline, Edit, EditNote, Filter, Search } from '@mui/icons-material';
import CustomAxios from '../utils/CustomAxios';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllOrders } from '../redux/orders';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom"
import { Calendar } from "primereact/calendar"
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import IndianNumFormat from './numberFormat';
export default function OrderList({ texttoFilter }) {
  const dispatch = useDispatch()
  const allOrders = useSelector(state => state.orders.AllOrders)
  const navigate = useNavigate()
  const [filteredOrder,setFilteredOrder]=useState()
  const [filteredData,setFilteredData]=useState({})
  const filtertext = texttoFilter
  useEffect(() => {
    getOrders()
  }, [filteredData])
  const [Filters,setFilters] = useState({
    search:"",
    status:"",
    category:""
  })
  const getOrders = () => {
    CustomAxios.post('listOrders/',{filteredData}).then((res) => {
      // console.log(res.data)
      dispatch(updateAllOrders(res.data))
    })
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(()=>{

// console.log(Filters)
    let isFiltered = false
        let data = ""
        // console.log("??????????????????????????????????/filterred data",Filters,products)
        if(Filters.category?.trim()&&Filters.status?.trim()){
          data = allOrders?.filter((e)=>{
            if (Filters.category=='cashfree'){
              return (e.paymentmethod==='cashfree' || e.paymentmethod=='razorapay') && e.status==Filters.status

            }
            else{
              return e.paymentmethod === Filters.category && e.status==Filters.status
            }
          })
        }
       
         else if(Filters.category?.trim()){
          data = allOrders?.filter((e)=>{
              if(Filters.category=='cashfree'){
                return Filters.category=='cashfree'|| e.paymentmethod=='razorapay'

              }
              else {
                return Filters.category==e.paymentmethod
              }
          } )
          isFiltered = true
        }
        else if(Filters.status?.trim()){
            
                data = allOrders?.filter((e)=>e.status==Filters.status )
          
         
          
          isFiltered = true
        }
        else if(Filters.search?.trim()){
          // console.log(allOrders)
            // console.log("??????????????????????????products to lower case sensitive", products.filter((e)=>e.products?.name.toLowerCase().includes(Filters.search.toLowerCase())))
            // console.log("sadnaj",Filters.search.toLowerCase(),allOrders.filter((e)=>e.user_details.username.toLowerCase().includes(Filters.search.toLowerCase()) || e.order_id?.toLowerCase().includes(Filters.search.toLowerCase())  ||e.cash_pickup_id?.toLowerCase().includes(Filters.search.toLowerCase())))
          data = allOrders.filter((e)=>e.user_details?.username?.toLowerCase().includes(Filters.search.toLowerCase()) || e.order_id?.toLowerCase().includes(Filters.search.toLowerCase())  ||e.cash_pickup_id?.toLowerCase().includes(Filters.search.toLowerCase()))
          isFiltered = true
        //   console.log(data)
        }
        else if( (!Filters.category && !Filters.status) || (!Filters.search && !Filters.category && !Filters.status) ){
            // console.log("????????????????????????????/inside else if condidtion",)
          data = null
        }
        // console.log(data)
        // if(Filters.search?.trim() && isFiltered && filteredOrder?.length >=1 ){
        //   console.log("sadnaj",Filters.search.toLowerCase(),allOrders.filter((e)=>e.user_details.username.toLowerCase().includes(Filters.search.toLowerCase()) || e.order_id?.toLowerCase().includes(Filters.search.toLowerCase())  ||e.cash_pickup_id?.toLowerCase().includes(Filters.search.toLowerCase())))
        //     data = allOrders.filter((e)=>e.user_details.username.toLowerCase().includes(Filters.search.toLowerCase()) || e.order_id?.toLowerCase().includes(Filters.search.toLowerCase())  ||e.cash_pickup_id?.toLowerCase().includes(Filters.search.toLowerCase()))
        // }
        // console.log(data)
        setFilteredOrder(data)

  },[Filters])

// console.log(filteredOrder)

  const handlePlaceholder = (val,placeholder)=>{
    console.log(val)
    if(!val){
      return <Typography sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</Typography>
    }
    else {
      if (val=='cashfree'){
        return "Online Payment"
      }
      else if(val==="mayicash"){
        return "Mayi Cash"
      }
      else if(val==="cod"){
        return "Cash On Delivery"

      }
      else if(val==="cash"){
        return "Cash On Pickup"
      }
      else{
        return val
      }
    }
    

    
  }
  return (
    <Box sx={{}}>
      <Box sx={{display:"flex",flexDirection:"column",gap:"24px"}}>
      <Box sx={{ padding: "5px", display: "flex", justifyContent: "space-around", padding: "16px", borderRadius: "5px" }}>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                        {IndianNumFormat(allOrders?.length)}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                        Total Orders
                    </Typography>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                        {IndianNumFormat(allOrders?.filter(item => item.status=='In Progress').length)}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                        In Progress
                    </Typography>

                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                    {IndianNumFormat(allOrders?.filter(item => item.status=='Order Completed').length)}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                        completed
                    </Typography>

                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                    {IndianNumFormat(allOrders?.filter(item => item.status=='Order Cancelled').length)}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                        Cancelled
                    </Typography>

                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "24px", fontWeigt: "32px", fontWeight: "500" }}>
                        {IndianNumFormat(allOrders?.filter(item => item.status=='Payment pending').length)}
                        </Typography>
                        <Typography sx={{ fontSize: "14px", fontWeigt: "20px", fontWeight: "400" }}>
                            Payment Pending
                        </Typography>

                    </Box>
            </Box>


        <Stack direction={'row'} sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Stack direction={'row'} gap={'20px'}>
            <InputBase inputProps={{ className: 'product-search' }} value={Filters.search} startAdornment={<Search sx={{ color: "#53AEFF",borderRadius:"4px" }} />} placeholder='Order Id / Customer Name ' onChange={(e) => { setFilters({ ...Filters, search: e.target.value }) }} sx={{ p: "8px 12px", gap: "8px", border: "1px solid #CBD5E1", borderRadius: "4px", height: "36px", width: "298px" }} />
            <Select displayEmpty value={Filters.status} sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "185px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" } ,"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} onChange={(e) => setFilters({ ...Filters, status: e.target.value })} renderValue={(val) => handlePlaceholder(val, 'Status')}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Order Completed">Order Completed</MenuItem>
              <MenuItem value="Payment pending">Payment Pending</MenuItem>
              <MenuItem value="Order Cancelled">Order Cancelled</MenuItem>
            </Select>
            <Select displayEmpty value={Filters.category} sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "185px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" },"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"} }} onChange={(e) => setFilters({ ...Filters, category: e.target.value })} renderValue={(val) => handlePlaceholder(val, 'Payment Method')}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="cashfree">Online Payment</MenuItem>
              <MenuItem value="mayicash">Mayi Cash</MenuItem>
              <MenuItem value="cash">Cash On Pickup</MenuItem>
              <MenuItem value="cod">Cash On Delivery</MenuItem>

            </Select>
  
            <Calendar placeholder="Data Range" value={filteredData.dateRange} style={{height:"36px",}} onChange={(e) =>setFilteredData({ ...filteredData,dateRange:e.value})} selectionMode="range" readOnlyInput hideOnRangeSelection />
           
          </Stack>
          {/* <Button sx={{ p: "8px 12px", textTransform: "none", borderRadius: "4px", "&:hover": { color: "#fff" } }} variant="contained" component={Link} to="/product/new" >+ Create Product</Button> */}
        </Stack>
      <Box>
      <TableContainer sx={{ bgcolor: "#fff", height: "55vh", overflowY: "auto" }}>
        <Table sx={{ }} stickyHeader aria-label="sticky table">
          <TableHead sx={{ background: "#E2E8F0" }}>
            <TableRow sx={{ background: "#E2E8F0"}}>
              {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Tracking Number</TableCell> */}
              <TableCell sx={{ fontWeight: "600", textAlign: "left",background: "#E2E8F0" }}>Order Id</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Customer</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Products</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Order Date</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Delivery Fee</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Total</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0"}} align="right">Status</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {
                (filteredOrder?filteredOrder: allOrders)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, o_id) => {
                return (

                  <TableRow
                    key={o_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>
                    <InputBase fullWidth  endAdornment={<Edit sx={{width:"16px",height:"16px",cursor:"pointer"}} onClick={()=>// console.log("edit clicked for awb")}/>} sx={{border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }} />
                  </TableCell> */}
                    <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>{order.razorpay_order_id || order.cash_pickup_id || order.order_id}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order?.user_details?.username || order?.user_details?.first_name + order?.user_details?.last_name}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order.carts.length}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{new Date(order.created_on).toLocaleDateString()}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{parseFloat(order.delivery_charge)}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{parseFloat(order.total_amount)}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order.status}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>
                      {order.status === "Payment pending" ?
                        <VisibilityOff sx={{ color: "#6c6c6c80" }} />
                        :
                        <Visibility sx={{ color: "#6c6c6c", cursor: "pointer" }} onClick={() => navigate(`${order.razorpay_order_id || order.cash_pickup_id || order.order_id}`)} />
                      }
                    </TableCell>
                  </TableRow>
                )
              })
            }

          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredOrder?filteredOrder.length: allOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Box>
      </Box>
    </Box>

  );
}
